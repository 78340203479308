<template>
	<div>
		<a-modal okText="确定" cancelText="取消" v-model="show" title="添加分类" @ok="comfirm">
			<template>
				<!-- 表单验证 -->
				<a-form ref="collectionForm" :form="form">

					<template v-if="record.classify">
						<a-form-item label="上级分类">
							<a-select @change="getselect" v-decorator="[
								'p_id',
								{ 
									initialValue: record.classify[0].id,
									rules: [{ required: true, message: '请填写'}] 
								},
							  ]">
								<a-select-option v-for="(item,index) in record.classify" :key="item.id">
									{{item.name}}
								</a-select-option>
					
							</a-select>
						</a-form-item>
					</template>


					<!-- 名称 -->
					<a-form-item label="名称">
						<a-input v-decorator="[
							'name',
							{
							  initialValue: record.data.name,
							  rules: [
								  { required: true, message: `请填写名称`, whitespace: true }
								  
								  ],
							},
						  ]" />
					</a-form-item>
					
					<!-- 图片icon -->
					<template v-if="pid == 0">
						<a-form-item label="点击更换icon(494X190)">
							<a-input style="display: none;" v-decorator="[
								'icon',
								{
								  initialValue: record.data.img,
								 
								},
							  ]" />
							<template v-if="record.data.img">
								<img style="width: 22%;height: 60%;cursor: pointer;" :src="record.data.img" alt="" @click="openresources(1)">  
							</template>
							
							<template v-else>
							  <div style="width: 25%;height: 60%;border:1px dashed #CCCCCC;flex-direction: column;padding: 30px;cursor: pointer;" class="upload f-ajc" @click="openresources(2)">
								<a-icon type="plus" />
								<div class="ant-upload-text">
									上传图片
								</div>
							  </div>
							</template>
						</a-form-item>
					</template>
																																				
				</a-form>
				<!-- 图片管理器 -->
				<resources ref="resources"  @getimgpath="getimgpath"></resources>
			</template>


		</a-modal>
	</div>
</template>

<script>
	import resources from "../resoureces.vue"
	export default {
		components: {
			resources
		},
		props: {
			record: Object
		},
		computed: {
			// 级联选中默认
			cascader() {
				if (this.record.shop) {
					let arr = []
					arr.push(this.record.shop[0].value)
					arr.push(this.record.shop[0].children[0].value)
					arr.push(this.record.shop[0].children[0].children[0].value)
					return arr
				}
			},

		},
		data() {
			return {
				show: false,
				isresources: false,
				form: this.$form.createForm(this, {
					name: 'coordinated'
				}),
				pid:0,//默认顶级分类
			}
		},

		created() {

		},
		methods: {
			// 获取类型
			gettype(e) {
				// console.log(e, "类型");
				this.type = e.target.value.toString() 
				console.log(this.type, "类型");
			},
			// 获取商品
			getcascader(e) {
				console.log(e, "获取商品");
			},
			// 获取分类
			getselect(e) {
				console.log(e, "获取选中分类id");
				this.pid = e
			},
			// 打开图片管理器
			openresources(btn) {
				
				this.witchbtn  = btn
				// this.isresources = true
				this.$refs.resources.visibles = true
			},

			// 获取更改图片路径
			getimgpath(val) {
				// 收集数据集
				this.$emit("changeimg", val)
			},
			// 校验图片
			handcheckimg(rule,value,callback){
				
				if(this.record.data.img == ''){
					callback("请上传图片")
				}else{
					console.log(this.record.data.img,"对应值");
				}
				callback()
			},
			
			comfirm(e) {
				
				console.log(e);
				const form = this.$refs.collectionForm.form;
				// form.setFieldsValue({}'icon',this.record.data.img)
				form.validateFields((err, values) => {
					if (err) {
						return;
					}
					console.log('Received values of form: ', values);
					// values.service_id = values.service_id[values.service_id.length-1]
					// 执行提交方法
					this.$emit("modifynav", values)
				});
			},
		}
	}
</script>

<style scoped lang="less">

</style>
