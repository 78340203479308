var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"okText":"确定","cancelText":"取消","title":"添加分类"},on:{"ok":_vm.comfirm},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[[_c('a-form',{ref:"collectionForm",attrs:{"form":_vm.form}},[(_vm.record.classify)?[_c('a-form-item',{attrs:{"label":"上级分类"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'p_id',
							{ 
								initialValue: _vm.record.classify[0].id,
								rules: [{ required: true, message: '请填写'}] 
							},
						  ]),expression:"[\n\t\t\t\t\t\t\t'p_id',\n\t\t\t\t\t\t\t{ \n\t\t\t\t\t\t\t\tinitialValue: record.classify[0].id,\n\t\t\t\t\t\t\t\trules: [{ required: true, message: '请填写'}] \n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t  ]"}],on:{"change":_vm.getselect}},_vm._l((_vm.record.classify),function(item,index){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)]:_vm._e(),_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'name',
						{
						  initialValue: _vm.record.data.name,
						  rules: [
							  { required: true, message: `请填写名称`, whitespace: true }
							  
							  ],
						},
					  ]),expression:"[\n\t\t\t\t\t\t'name',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t  initialValue: record.data.name,\n\t\t\t\t\t\t  rules: [\n\t\t\t\t\t\t\t  { required: true, message: `请填写名称`, whitespace: true }\n\t\t\t\t\t\t\t  \n\t\t\t\t\t\t\t  ],\n\t\t\t\t\t\t},\n\t\t\t\t\t  ]"}]})],1),(_vm.pid == 0)?[_c('a-form-item',{attrs:{"label":"点击更换icon(494X190)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'icon',
							{
							  initialValue: _vm.record.data.img,
							 
							},
						  ]),expression:"[\n\t\t\t\t\t\t\t'icon',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t  initialValue: record.data.img,\n\t\t\t\t\t\t\t \n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t  ]"}],staticStyle:{"display":"none"}}),(_vm.record.data.img)?[_c('img',{staticStyle:{"width":"22%","height":"60%","cursor":"pointer"},attrs:{"src":_vm.record.data.img,"alt":""},on:{"click":function($event){return _vm.openresources(1)}}})]:[_c('div',{staticClass:"upload f-ajc",staticStyle:{"width":"25%","height":"60%","border":"1px dashed #CCCCCC","flex-direction":"column","padding":"30px","cursor":"pointer"},on:{"click":function($event){return _vm.openresources(2)}}},[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" 上传图片 ")])],1)]],2)]:_vm._e()],2),_c('resources',{ref:"resources",on:{"getimgpath":_vm.getimgpath}})]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }